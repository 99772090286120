define("discourse/plugins/discourse-group-tracker/discourse/raw-views/tracker-topic-status", ["exports", "@ember/object", "discourse/lib/raw-render-glimmer", "discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-topic-status", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _object, _rawRenderGlimmer, _groupTrackerTopicStatus, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _object.default {
    get html() {
      return (0, _rawRenderGlimmer.default)(this, "tracker-topic-status", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
      /*
        <GroupTrackerTopicStatus @context={{@data}} />
      */
      {
        "id": "o9KI0Ras",
        "block": "[[[8,[32,0],null,[[\"@context\"],[[30,1]]],null]],[\"@data\"],false,[]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-group-tracker/discourse/raw-views/tracker-topic-status.js",
        "scope": () => [_groupTrackerTopicStatus.default],
        "isStrictMode": true
      }), (0, _templateOnly.default)()), this.context);
    }
  }
  _exports.default = _default;
});